import React, { useContext, useEffect, useState } from "react";
import "./FeaturedEvent.css";
import { Link, useParams } from "react-router-dom";
import { Logo, ProfileAvatar } from "../../imports";
import Button from "../../components/button/Button";
import Share from "../../components/share/Share";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import useGeoLocation from "../../components/geolocation/UseGeoLocation";
import FeaturedModal from "../../components/modal/FeaturedModal";
import MobileDetect from "../../components/modal/MobileDetect";
import Context from "../../components/context/Context";
import AuthModal from "../../components/modal/AuthModal";
import Linkify from "react-linkify";
import { atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";
import { ThemeContext } from "../../App";
import Toggle from "../../components/toggle/Toggle";
import Addtocalendar from "../../components/button/Addtocalendar";
import LazyLoad from "../../components/lazy load/LazyLoad";

function FeaturedEvent() {
  let { id } = useParams();
  const { user } = useContext(Context);
  const [event, setEvent] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [mobilePrompt, setMobilePrompt] = useState(false);
  const [isGoing, setIsGoing] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { theme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(false);

  const [upcomingDate, setUpcomingDate] = useState([]);

  //  upcoming event date

  const now = Date.now();
  const futureDates = event.timeArr?.filter((date) => {
    // Filter out dates in the past or falsey values
    return date && new Date(date.date).getTime() > now;
  });

  useEffect(() => {
    futureDates && setUpcomingDate(futureDates);
  }, [event]);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  // accessing user token and knowing isGoing state
  useEffect(() => {
    try {
      const isGoingApi = async () => {
        const token = user?.accessToken;
        user &&
          (await axios
            .get(
              `https://us-central1-heavenya.cloudfunctions.net/isGoingToEvent?eventKey=${id}&access_token=${token}`
            )
            .then((res) => {
              setIsGoing(res.data?.isGoing);
            }));
      };

      isGoingApi();
    } catch (error) {
      console.log(error);
    }
  });

  console.log(upcomingDate);

  // users location
  const location = useGeoLocation();
  const locationLat = location.loaded
    ? JSON.stringify(location.coordinates.lat)
    : "";
  const locationLong = location.loaded
    ? JSON.stringify(location.coordinates.lng)
    : "";

  // event location
  const eventLat = event.location?.lat;
  const eventLong = event.location?.lon;

  const eventDetails = `https://us-central1-heavenya.cloudfunctions.net/getEventDetails?key=${id}`;
  // modal states
  const openModal = () => {
    setShowModal((prev) => !prev);
    setEvent(event);
  };

  // auth modal

  const openAuthModal = () => {
    setShowAuthModal((prev) => !prev);
  };

  // toggling event state

  const toggleEventGoing = async () => {
    const token = user.accessToken;
    await axios
      .get(
        `https://us-central1-heavenya.cloudfunctions.net/toggleEventGoing?eventKey=${id}&value=YES&access_token=${token}`
      )
      .then((res) =>
        window.navigator.userAgent.toLowerCase().indexOf("android") > -1
          ? (window.location.href =
              "https://play.google.com/store/apps/details?id=com.faithful.heavenya")
          : window.navigator.userAgent.toLowerCase().indexOf("iphone") > -1
          ? (window.location.href =
              "https://apps.apple.com/app/apple-store/id1498340882")
          : window.location.reload()
      );
  };

  const toggleEventGoingOff = async () => {
    const token = user.accessToken;
    await axios
      .get(
        `https://us-central1-heavenya.cloudfunctions.net/toggleEventGoing?eventKey=${id}&value=NO&access_token=${token}`
      )
      .then((res) => window.location.reload());
  };

  // mobile detector

  useEffect(() => {
    window.screen.width < 1200 ? setMobilePrompt(true) : setMobilePrompt(false);
  }, []);

  useEffect(() => {
    try {
      axios.get(eventDetails).then((res) => {
        setEvent(res.data?.details);
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, [setEvent]);

  // function to calculate the distance and return value in miles

  const distance = (
    lat1 = locationLat,
    lon1 = locationLong,
    lat2 = eventLat,
    lon2 = eventLong
  ) => {
    const Radius = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = Radius * c; // Distance in km

    return (distance * 0.621371).toFixed(); //round off distance
  };

  // degree function

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const miles = distance().toString();

  const name = event.name;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <Toggle />

      {isLoading ? (
        <LazyLoad />
      ) : (
        <>
          <div className={darkMode ? "featured dark" : "featured"}>
            <div className="header__logo">
              <div className="header__logo__first"></div>

              <div className="header__logo__img">
                <a href="/">
                  <img src={Logo} alt="logo" />
                </a>
              </div>
              {user ? (
                <div className="header__nav user">
                  {user.photoURL !== null ? (
                    <div className="header__nav__signin">{user.photoURL}</div>
                  ) : (
                    <div className="header__nav__photo">
                      <img src={ProfileAvatar} alt="profile avatar" />
                    </div>
                  )}
                  <div className="header__nav__event">
                    <a href="/addevent">my events</a>
                  </div>
                </div>
              ) : (
                <div className="header__nav">
                  <div className="header__nav__event">my events</div>

                  <div className="header__nav__signin">sign in</div>
                </div>
              )}
            </div>

            {event.vid ? (
              <div className="featured__banner">
                <video
                  className="featured__banner__main"
                  controls
                  autoPlay
                  unmuted
                  playsinline
                  src={event.vid?.src}
                ></video>
              </div>
            ) : (
              <div className="featured__banner">
                <img
                  src={event.img}
                  alt="banner"
                  className="featured__banner__main"
                />
              </div>
            )}

            <div className="featured__banner__footer">
              <div
                className="featured__banner__footer__icon"
                onClick={openModal}
              >
                <Share />
              </div>

              <div className="featured__banner__footer__event">
                {event.name}
              </div>
            </div>

            <div className="featured__maincontainer">
              <div className="featured__maincontainer__img">
                {event.goingImage?.map((img, i) => {
                  return (
                    <div className="featured__maincontainer__avatars" key={i}>
                      <img src={img} alt="avatar" />
                    </div>
                  );
                })}
              </div>

              <div className="featured__maincontainer__hero">
                <div className="featured__maincontainer__hero__maininfo">
                  <div className="featured__maincontainer__hero__maininfo__heading">
                    main information
                  </div>
                  <div className="featured__maincontainer__hero__maininfo__container">
                    <div className="featured__maincontainer__hero__maininfo__container__icon">
                      <WatchLaterIcon />
                    </div>

                    <div>
                      {event.eventuality === "OVERNIGHT" ? (
                        event.timeArr?.map((time, i) => {
                          return (
                            <div
                              className="featured__maincontainer__hero__maininfo__container__text"
                              key={i}
                            >
                              {/* <p>{new Date(time.date).toLocaleDateString()}</p> */}
                              <p>
                                FROM{" "}
                                {new Date(time.sTime)
                                  .toLocaleString([], { hour12: true })
                                  .replace(/(.*)\D\d+/, "$1")}{" "}
                                <br />
                                TILL{" "}
                                {new Date(time.eTime)
                                  .toLocaleString([], { hour12: true })
                                  .replace(/(.*)\D\d+/, "$1")}{" "}
                                (LOCAL TIME)
                              </p>
                            </div>
                          );
                        })
                      ) : event.eventuality === "MULTIPLE DAYS" ? (
                        <div className="featured__maincontainer__hero__maininfo__container__text">
                          {upcomingDate.length == 0 ? (
                            <p>
                              {new Date(
                                event.timeArr[event.timeArr?.length - 1]?.sTime
                              )
                                .toLocaleString([], { hour12: true })
                                .replace(/(.*)\D\d+/, "$1")}{" "}
                              (LOCAL TIME)
                            </p>
                          ) : (
                            <p>
                              {new Date(upcomingDate[0]?.sTime)
                                .toLocaleString([], { hour12: true })
                                .replace(/(.*)\D\d+/, "$1")}{" "}
                              (LOCAL TIME)
                            </p>
                          )}
                        </div>
                      ) : (
                        event.timeArr?.map((time, index) => {
                          return (
                            <div
                              className="featured__maincontainer__hero__maininfo__container__text"
                              key={index}
                            >
                              <p>
                                {new Date(time.sTime)
                                  .toLocaleString([], { hour12: true })
                                  .replace(/(.*)\D\d+/, "$1")}{" "}
                                (LOCAL TIME)
                              </p>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>

                  <div className="featured__maincontainer__hero__maininfo__container">
                    <div className="featured__maincontainer__hero__maininfo__container__icon">
                      <PersonIcon />
                    </div>

                    <div className="featured__maincontainer__hero__maininfo__container__text">
                      {event.spotsTaken}
                    </div>
                  </div>

                  <div className="featured__maincontainer__hero__maininfo__container">
                    <div className="featured__maincontainer__hero__maininfo__container__icon">
                      <LocationOnIcon />
                    </div>
                    {miles !== "NaN" && (
                      <div className="featured__maincontainer__hero__maininfo__container__text">
                        {miles} miles away
                      </div>
                    )}
                  </div>
                </div>

                <div className="featured__maincontainer__hero__location">
                  <div className="featured__maincontainer__hero__location__heading">
                    location address
                  </div>

                  <div className="featured__maincontainer__hero__location__text">
                    {event.location?.address}
                  </div>
                </div>

                <div className="featured__maincontainer__hero__details">
                  <div className="featured__maincontainer__hero__details__heading">
                    details
                  </div>

                  <div className="featured__maincontainer__hero__details__text">
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          target="blank"
                          href={decoratedHref}
                          key={key}
                          className={
                            darkMode &&
                            "featured__maincontainer__hero__details__text__link"
                          }
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <p
                        className={
                          darkMode &&
                          "featured__maincontainer__hero__details__text dark"
                        }
                      >
                        {event.description} <br />
                      </p>
                    </Linkify>
                    {event.crawledLink && (
                      <div
                        className={
                          darkMode &&
                          "featured__maincontainer__hero__details__eventbrite"
                        }
                      >
                        Please visit{" "}
                        <a
                          href={event.crawledLink}
                          rel="noreferrer"
                          target="_blank"
                          id="crawl"
                        >
                          {event.crawledLink}
                        </a>{" "}
                        for more info
                      </div>
                    )}
                  </div>
                </div>

                {!user &&
                event.timeArr[event.timeArr.length - 1]?.sTime < Date.now() ? (
                  <div className="featured__maincontainer__hero__attend">
                    <div className="button past">past</div>
                  </div>
                ) : !user ? (
                  <div
                    className="featured__maincontainer__hero__attend"
                    onClick={openAuthModal}
                  >
                    <Button text="attend" />
                  </div>
                ) : isGoing === true ? (
                  <div>
                    <div
                      className="featured__maincontainer__hero__attend"
                      onClick={toggleEventGoingOff}
                    >
                      <Button text="going" />
                    </div>

                    {/* add to calendar */}
                    {/* <form
                      onSubmit={(e) => {
                        const startYear = new Date(
                          event.timeArr[0].sTime
                        ).getFullYear();

                        const startMonth =
                          new Date(event.timeArr[0].sTime).getMonth() + 1;

                        const startDay = new Date(
                          event.timeArr[0].sTime
                        ).getDate();

                        const startTime = new Date(event.timeArr[0].sTime)
                          .toISOString([], { hour12: true })
                          .substring(11, 16);

                        const endYear = new Date(
                          event.timeArr[event.timeArr?.length - 1].date
                        ).getFullYear();

                        const endMonth =
                          new Date(
                            event.timeArr[event.timeArr?.length - 1].date
                          ).getMonth() + 1;

                        const endDay = new Date(
                          event.timeArr[event.timeArr?.length - 1].date
                        ).getDate();

                        const endTime = new Date(
                          event.timeArr[event.timeArr?.length - 1].eTime
                        )
                          .toISOString([], { hour12: true })
                          .substring(11, 16);

                        const timeZone =
                          Intl.DateTimeFormat().resolvedOptions().timeZone;

                        e.preventDefault();
                        atcb_action({
                          name,
                          startDate:
                            startYear + "-" + startMonth + "-" + startDay,
                          endDate: endYear + "-" + endMonth + "-" + endDay,
                          options: [
                            "Apple",
                            "Google",
                            "Microsoft365",
                            // "iCal",
                            // "Outlook.com",
                            // "Yahoo",
                          ],
                          timeZone: timeZone,
                          iCalFileName: "Reminder-Event",
                          description: event.description,
                          location: event.location?.address,
                          startTime:
                            event.eventuality === "ONE DAY" ? null : startTime,
                          endTime:
                            event.eventuality === "ONE DAY" ? null : endTime,
                        });
                      }}
                    >
                      <div className="add__to__calendar__container">
                        <button type="submit" style={{ border: "none" }}>
                          <Addtocalendar text={"add to calendar"} />
                        </button>
                      </div>
                    </form> */}

                    <div title="Add to Calendar" class="addeventatc">
                      Add to Calendar
                      <span class="start">
                        {new Date(event.timeArr[0].sTime).getMonth() + 1}/
                        {event.timeArr.length > 1 &&
                        event.eventuality === "MULTIPLE DAYS"
                          ? new Date(event.timeArr[0].sTime).getDate() - 1
                          : new Date(event.timeArr[0].sTime).getDate()}
                        /{new Date(event.timeArr[0].date).getFullYear()}{" "}
                        {new Date(event.timeArr[0]?.sTime)
                          .toLocaleTimeString([], { hour12: true })
                          .replace(/(.*)\D\d+/, "$1")}
                      </span>
                      <span class="end">
                        {event.timeArr.length > 1
                          ? new Date(
                              event.timeArr[event.timeArr.length - 1].eTime
                            ).getMonth() + 1
                          : new Date(event.timeArr[0].eTime).getMonth() + 1}
                        /
                        {event.timeArr.length > 1 &&
                        event.eventuality === "MULTIPLE DAYS"
                          ? new Date(
                              event.timeArr[event.timeArr.length - 1].eTime
                            ).getDate() - 1
                          : event.timeArr.length > 1
                          ? new Date(
                              event.timeArr[event.timeArr.length - 1].eTime
                            ).getDate()
                          : new Date(event.timeArr[0].eTime).getDate()}
                        /
                        {event.timeArr.length > 1
                          ? new Date(
                              event.timeArr[event.timeArr.length - 1].eTime
                            ).getFullYear()
                          : new Date(event.timeArr[0].eTime).getFullYear()}{" "}
                        {new Date(
                          event.timeArr[event.timeArr.length - 1]?.eTime
                        )
                          .toLocaleTimeString([], { hour12: true })
                          .replace(/(.*)\D\d+/, "$1")}
                      </span>
                      <span class="timezone">{timeZone}</span>
                      <span class="title">{event.name}</span>
                      <span class="description">{event.description}</span>
                      <span class="location">{event.location?.address}</span>
                    </div>
                  </div>
                ) : !isLoading &&
                  event.timeArr[event.timeArr.length - 1]?.sTime <
                    Date.now() ? (
                  <div className="button past">past</div>
                ) : (
                  <div
                    className="featured__maincontainer__hero__attend"
                    onClick={toggleEventGoing}
                  >
                    <Button text="attend" />
                  </div>
                )}
              </div>
            </div>
            <FeaturedModal
              showModal={showModal}
              setShowModal={setShowModal}
              name={event.name}
              userId={event.key}
              userIdTrunc={id.toString().slice(0, 5) + "..."} // truncating event key
            />

            <MobileDetect
              mobilePrompt={mobilePrompt}
              setMobilePrompt={setMobilePrompt}
            />

            <AuthModal
              showAuthModal={showAuthModal}
              setShowAuthModal={setShowAuthModal}
            />
          </div>
        </>
      )}
    </>
  );
}

export default FeaturedEvent;
