import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ThemeContext } from "../../App";
import "./Button.css";

function Button({ text }) {
  const [darkMode, setDarkMode] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  return (
    <>
      <div className={darkMode ? "button dark" : "button"}>{text}</div>
    </>
  );
}

export default Button;
