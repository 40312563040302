import React, { useContext, useEffect, useState } from "react";
import { signInWithApple, signInWithGoogle } from "../../firebase";
import { AppleAuth, GoogleAuth } from "../../imports";
import RegisterModal from "./RegisterModal";
import "./AuthModal.css";
import { ThemeContext } from "../../App";

function AuthModal({ showAuthModal, setShowAuthModal }) {
  const [showModal, setShowModal] = useState(false);

  const [darkMode, setDarkMode] = useState(false);

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  // register modal

  const openRegisterModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      {showAuthModal ? (
        <div className="authmodal" onClick={() => setShowAuthModal(false)}>
          <div
            className={
              darkMode ? "authmodal__container dark" : "authmodal__container"
            }
            onClick={(e) => e.stopPropagation()}
          >
            <div className="authmodal__container__header">My Profile</div>

            <div className="authmodal__container__body">
              <div
                className="authmodal__container__body__apple"
                onClick={signInWithApple}
              >
                <img src={AppleAuth} alt="apple logo" id="apple" />
                <h4>Continue with Apple</h4>
              </div>

              <div
                className="authmodal__container__body__google"
                onClick={signInWithGoogle}
              >
                <img src={GoogleAuth} alt="google logo" />
                <h4>Continue with Google</h4>
              </div>

              <div
                className="authmodal__container__body__email"
                onClick={openRegisterModal}
              >
                <h4>Continue with Heavenya</h4>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <RegisterModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}

export default AuthModal;
