import React, { useContext, useEffect, useState } from "react";
import "./MoreModal.css";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import HardwareIcon from "@mui/icons-material/Hardware";
import PeopleIcon from "@mui/icons-material/People";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import WorkIcon from "@mui/icons-material/Work";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EmailIcon from "@mui/icons-material/Email";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";

function MoreModal({ showMoreModal, setShowMoreModal }) {
  const [darkMode, setDarkMode] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);
  return (
    <>
      {showMoreModal ? (
        <div className="moremodal" onClick={() => setShowMoreModal(false)}>
          <div
            className={
              darkMode ? "moremodal__container dark" : "moremodal__container"
            }
            onClick={(e) => e.stopPropagation()}
          >
            <div className="moremodal__container__header">More</div>

            <div className="moremodal__container__body">
              <Link to={"/legal"} target="_blank" rel="noreferrer">
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <HardwareIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    legal
                  </div>
                </div>
              </Link>

              <a
                href="https://heavenya.canny.io"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <LightbulbIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    Share Ideas
                  </div>
                </div>
              </a>

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSc7Tsk6tRbp2Y5i1ZotKY-N5IBgPnOmGKECybUXdcXjCBeulQ/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <PeopleIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    join the team
                  </div>
                </div>
              </a>

              <a
                href="https://heavenya.medium.com/additional-information-2b425345c4e0"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <LibraryBooksIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    information
                  </div>
                </div>
              </a>

              {/* <a
                href={`${window.location.pathname}?form=donate`}
                rel="noreferrer"
              > */}

              <a
              // href="https://offer.heavenya.com"
              // target="_blank"
              // rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <EmojiEventsIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    Offer
                  </div>
                </div>
              </a>

              <a
                href="https:heavenya.org/pay"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <MonetizationOn />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    payment
                  </div>
                </div>
              </a>

              <a
                href="https://heavenya.org/donate"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <VolunteerActivismIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    donate
                  </div>
                </div>
              </a>

              <a
              // href="https://newlivingstyle.com/collections/heavenya"
              // target="_blank"
              // rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <WorkIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    brand
                  </div>
                </div>
              </a>

              <a
                href="https://opensea.io/Heavenya"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <SignalCellularAltIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    NFTS
                  </div>
                </div>
              </a>

              <a
                href="https://trello.com/b/BmCeeNK9/development-team"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <FormatListBulletedIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    roadmap
                  </div>
                </div>
              </a>

              <a
                href="mailto:support@heavenya.com"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <EmailIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    contact us
                  </div>
                </div>
              </a>

              <a
                href="https://drive.google.com/drive/u/1/folders/1J4cBh-KA8Ta7iko_d01vZ4WMHYeB-j7l"
                target="_blank"
                rel="noreferrer"
              >
                <div className="moremodal__container__body__children">
                  <div className="moremodal__container__body__children__icon">
                    <NewspaperIcon />
                  </div>

                  <div className="moremodal__container__body__children__text">
                    press kit
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MoreModal;
