import React from "react";
import { signInWithApple } from "./firebase";

function Test2() {
  return (
    <>
      <button onClick={signInWithApple}>click me</button>
    </>
  );
}

export default Test2;
