import React, { useState, useEffect, useContext } from "react";
import "./Hero.css";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../button/Button";
import Share from "../share/Share";
import Modal from "../modal/Modal";
import axios from "axios";
import useGeoLocation from "../geolocation/UseGeoLocation";
import { ThemeContext } from "../../App";
import LazyFeed from "../lazy load/LazyFeed";
import "@culturehq/add-to-calendar/dist/styles.css";
import { FilterListIcon } from "../../imports";

function Hero() {
  const [filterMenu, setFilterMenu] = useState(false);

  const [visible, setVisible] = useState(12);
  const [searchVisible, setSearchVisible] = useState(48);

  const [pagination] = useState(50);
  const [page, setPage] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [event, setEvent] = useState(null); // creating a state for modal to get data for individual card

  const [query, setQuery] = useState("");
  const [featured, setFeatured] = useState([]);
  const [search, setSearch] = useState([]);

  // const [feature, setFeature] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [heroLoading, setHeroLoading] = useState(true);
  const [searchTimeout, setSearchTimeout] = useState(false);
  // const [myIp, setMyIp] = useState(false);

  const { theme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  const featuredEvent =
    "https://us-central1-heavenya.cloudfunctions.net/getFeaturedEvents";

  // getting ip address

  const dataWithIpAddress = "https://api.ipify.org/?format=json";

  // useEffect(() => {
  //   try {
  //     axios.get(dataWithIpAddress).then((res) => {
  //       if (res.data?.ip !== false) {
  //         setMyIp(res.data?.ip);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [myIp]);

  // global searching of event link

  const searchQuery = `https://us-central1-heavenya.cloudfunctions.net/searchEvents?search=${query}&page=${page}`;

  // useEffect(() => {
  //   axios.get(featuredEvent).then((res) => {
  //     setFeature(true);
  //     setLoading(false);
  //   });
  // }, []);

  const location = useGeoLocation();
  const locationLat = JSON.stringify(location.coordinates.lat);

  const locationLong = JSON.stringify(location.coordinates.lng);

  // event location
  const eventLat = featured.map((e) => e.location.lat);
  const eventLong = featured.map((e) => e.location.lon);

  // function to calculate the distance and return value in miles

  const distance = (
    lat1 = locationLat,
    lon1 = locationLong,
    lat2 = eventLat,
    lon2 = eventLong
  ) => {
    const Radius = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = Radius * c; // Distance in km

    return (distance * 0.621371).toFixed(); //round off distance
  };

  // degree function

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const miles = distance().toString();

  //  displaying event only when location is set
  useEffect(() => {
    if (location.loaded === true && location.coordinates.lat !== "1") {
      const featuredData = `https://us-central1-heavenya.cloudfunctions.net/getLocalizedEvent?lat=${locationLat}&lng=${locationLong}&page=${page}&pagination=${pagination}`;
      try {
        axios.get(featuredData).then((res) => {
          setFeatured(res.data?.data);
          setHeroLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    } else if (location.loaded === true && location.coordinates.lat === "1") {
      const featuredData = `https://us-central1-heavenya.cloudfunctions.net/getLocalizedEvent`;
      try {
        axios.get(featuredData).then((res) => {
          setFeatured(res.data?.data);
          setHeroLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [location]);

  // search handling

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const searchFunction = async () => {
        try {
          await axios
            .get(searchQuery)
            .then((res) => {
              setSearch(res.data?.data);
            })
            .then(() => setSearchTimeout(true));
        } catch (error) {
          // console.log(error);
        }
      };

      query.length > 0 && searchFunction();
    }, 700);

    return () => {
      clearTimeout(delayDebounceFn);
      setSearchTimeout(false);
    };
  }, [page, query]);

  // load more btn for local events

  const loadMoreLocal = () => {
    setVisible((prevValue) => prevValue + featured.length);
  };

  // load more btn for search queries
  const loadMoreSearch = () => {
    console.log(search.length);
    setSearchVisible(48);
    setPage((prevValue) => prevValue + 1);
    window.scrollTo(600, 600);
  };

  const delSearchInput = () => {
    setQuery("");
  };

  const openModal = (e) => {
    setShowModal((prev) => !prev);
    setEvent(e);
  };

  return (
    <>
      {!heroLoading ? (
        <>
          <div
            className={darkMode ? "hero__container dark" : "hero__container"}
          >
            <div className="hero">
              {/* heading or title of the hero section */}

              <div className="hero__heading">
                <div
                  className={
                    darkMode
                      ? "hero__heading__title dark"
                      : "hero__heading__title"
                  }
                >
                  Create Fellowship Opportunities In Your Local Community
                </div>

                <div
                  className="hero__heading__filter"
                  onClick={() => setFilterMenu(true)}
                >
                  <p>Filter</p>
                  <p>
                    <FilterListIcon />
                  </p>
                </div>

                {filterMenu ? (
                  <div
                    className="hero__heading__filter__content__main"
                    onClick={() => setFilterMenu(false)}
                  >
                    <div
                      className="hero__heading__filter__content"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="hero__heading__filter__content__detail">
                        <h3>Date</h3>

                        <div>
                          <p>upcoming</p>

                          <p>all</p>

                          <p>today</p>

                          <p>week</p>

                          <p>month</p>

                          <p>year</p>
                        </div>
                      </div>

                      <div className="hero__heading__filter__content__detail">
                        <h3>Type</h3>

                        <div>
                          <p>all</p>

                          <p>in person</p>

                          <p>virtual</p>
                        </div>
                      </div>

                      <div className="hero__heading__filter__content__detail">
                        <h3>Price</h3>

                        <div>
                          <p>all</p>

                          <p>free</p>

                          <p>paid</p>
                        </div>
                      </div>

                      <div className="hero__heading__filter__content__detail">
                        <h3>Time</h3>

                        <div>
                          <p>all</p>

                          <p>AM</p>

                          <p>PM</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div
                  className={
                    darkMode
                      ? "hero__heading__search dark"
                      : "hero__heading__search"
                  }
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="search events"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />

                  <div
                    className="hero__heading__title__close__icon"
                    onClick={delSearchInput}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>

              <div className="hero__cards__container">
                {/* mapping through the list of items imported from herocards component and implementing search filter and view more functionality */}

                {query === "" ? (
                  <>
                    {featured.slice(0, visible).map((card) => {
                      /* cards display */

                      return (
                        <div className="hero__cards" key={card.key}>
                          <div className="hero__cards__img">
                            <a
                              href={`/eventdetails/${card.name
                                .replace(/ /g, "-")
                                .replaceAll("?", "-")}/${card.key}`}
                              style={{ textDecoration: "none" }}
                            >
                              <img
                                src={card.img}
                                alt="card"
                                className="hero__cards__img__main"
                              />
                            </a>

                            <div
                              className="hero__cards__arrow"
                              onClick={() => openModal(card)}
                            >
                              <Share />
                            </div>
                          </div>
                          <div
                            className={
                              darkMode
                                ? "hero__cards__contents dark"
                                : "hero__cards__contents"
                            }
                          >
                            <a
                              href={`/eventdetails/${card.name
                                .replace(/ /g, "-")
                                .replaceAll("?", "-")}/${card.key}`}
                              style={{ textDecoration: "none" }}
                            >
                              <h4>{card.name}</h4>
                            </a>
                            <div className="hero__cards__contents__date">
                              {/* formatting date and time and displaying it */}

                              <p>
                                {new Date(
                                  card.timeArr[0]?.sTime
                                ).toDateString()}
                              </p>
                              <p>
                                {new Date(card.timeArr[0]?.sTime)
                                  .toLocaleTimeString([], { hour12: true })
                                  .replace(/(.*)\D\d+/, "$1")}
                                {""} - {""}
                                {new Date(
                                  card.timeArr[card.timeArr?.length - 1]?.eTime
                                )
                                  .toLocaleTimeString([], { hour12: true })
                                  .replace(/(.*)\D\d+/, "$1")}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {visible < featured.length && (
                      <div className="hero__view__more" onClick={loadMoreLocal}>
                        <Button text="load more" />
                      </div>
                    )}
                  </>
                ) : (
                  // search filtering and display
                  <>
                    {searchTimeout ? (
                      <>
                        {search
                          .filter((post) => {
                            if (query === "") {
                              return post;
                            } else if (
                              post.location?.address ||
                              "".toLowerCase().includes(query.toLowerCase())
                            ) {
                              return post;
                            } else if (
                              post.name
                                .toLowerCase()
                                .includes(query.toLowerCase())
                            ) {
                              return post;
                            }

                            return false;
                          })
                          .slice(0, searchVisible)
                          .map((card) => {
                            /* cards display */

                            return (
                              <div className="hero__cards" key={card.key}>
                                <div className="hero__cards__img">
                                  <a
                                    href={`/eventdetails/${card.name
                                      .replace(/ /g, "-")
                                      .replaceAll("?", "-")}/${card.key}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <img
                                      src={card.img}
                                      alt="card"
                                      className="hero__cards__img__main"
                                    />
                                  </a>

                                  <div
                                    className="hero__cards__arrow"
                                    onClick={() => openModal(card)}
                                  >
                                    <Share />
                                  </div>
                                </div>
                                <div
                                  className={
                                    darkMode
                                      ? "hero__cards__contents dark"
                                      : "hero__cards__contents"
                                  }
                                >
                                  <a
                                    href={`/eventdetails/${card.name
                                      .replace(/ /g, "-")
                                      .replaceAll("?", "-")}/${card.key}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <h4>{card.name}</h4>
                                  </a>

                                  <div className="hero__cards__contents__date">
                                    {/* formatting date and time and displaying it */}

                                    <p>
                                      {new Date(
                                        card.timeArr[0]?.sTime
                                      ).toDateString()}
                                    </p>
                                    <p>
                                      {new Date(card.timeArr[0]?.sTime)
                                        .toLocaleTimeString([], {
                                          hour12: true,
                                        })
                                        .replace(/(.*)\D\d+/, "$1")}
                                      {""} - {""}
                                      {new Date(
                                        card.timeArr[
                                          card.timeArr?.length - 1
                                        ]?.eTime
                                      )
                                        .toLocaleTimeString([], {
                                          hour12: true,
                                        })
                                        .replace(/(.*)\D\d+/, "$1")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {searchVisible < search.length && (
                          <div
                            className="hero__view__more"
                            onClick={loadMoreSearch}
                          >
                            <Button text="load more" />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <LazyFeed />

                        {/* <div className="load__spinner">
                          <CircularProgress />
                        </div> */}
                      </>
                    )}

                    {searchTimeout === true && search.length < 1 && (
                      <div className={darkMode ? "noresult dark" : "noresult"}>
                        Result not found.
                      </div>
                    )}
                  </>
                )}

                <Modal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  userId={event && event.key}
                  name={event && event.name}
                  eventImage={event && event.image}
                  userIdTrunc={event && event.key.toString()}
                  // eventNameTrunc={event && event.name.toString().slice(0, 8)} // truncating event name
                />
              </div>

              {/* importing a button component and passing props */}
            </div>
          </div>
        </>
      ) : (
        <LazyFeed />
        // <div className="load__spinner">
        //   <CircularProgress />
        // </div>
      )}
    </>
  );
}

export default Hero;
