import { useEffect } from "react";
import { useNavigate } from "react-router";

const LegalFooter = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/legal');
    }, []);

    return null;
}

export default LegalFooter;