import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import { AppleStore, GoogleStore } from "../../imports";
import "./MobileDetect.css";

function MobileDetect({ mobilePrompt, setMobilePrompt }) {
  const [darkMode, setDarkMode] = useState(false);

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  const appleStoreLink = "https://apps.apple.com/app/apple-store/id1498340882";
  const googleStoreLink =
    "https://play.google.com/store/apps/details?id=com.faithful.heavenya";

  return (
    <>
      {mobilePrompt ? (
        <div className="mobiledetect" onClick={() => setMobilePrompt(false)}>
          <div
            className={
              darkMode
                ? "mobiledetect__container dark"
                : "mobiledetect__container"
            }
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mobiledetect__container__img">
              <div className="mobiledetect__container__img__google">
                <a href={googleStoreLink} target="_blank" rel="noreferrer">
                  <img src={GoogleStore} alt="google store" />
                </a>
              </div>

              <div className="mobiledetect__container__img__apple">
                <a href={appleStoreLink} target="_blank" rel="noreferrer">
                  <img src={AppleStore} alt="apple store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MobileDetect;
