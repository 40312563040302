import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import "./LazyHead.css";

function LazyHead() {
  const { theme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);
  return (
    <>
      <div className={darkMode ? "lazyhead dark" : "lazyhead"}>
        <div className="lazyhead__header"></div>

        <div className="lazyhead__bottom">
          <div className="lazyhead__bottom__circle"></div>
          <div className="lazyhead__bottom__text"></div>
        </div>
      </div>
    </>
  );
}

export default LazyHead;
