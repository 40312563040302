import React from "react";
import "./404.css";

function Error404() {
  return (
    <>
      <div className="error">
        <div className="error__header">page not found</div>

        <div className="error__text">
          The page you were looking for could not be found. It might have been
          removed, renamed or didnt exist. You might want to navigate to the{" "}
          <a href="/">Homepage</a>
        </div>
      </div>
    </>
  );
}

export default Error404;
