import React, { useContext, useEffect, useState } from "react";
import "./Modal.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { ThemeContext } from "../../App";

function Modal({ showModal, setShowModal, userId, userIdTrunc, name }) {
  const [darkMode, setDarkMode] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  const url = `https://heavenya.com/eventdetails/${name}/${userId}`;
  const urlTruncate = `https://heavenya.com/eventdetails/${name}/${userIdTrunc}`;

  return (
    <>
      {showModal ? (
        <div className="modal" onClick={() => setShowModal(false)}>
          <div
            className={darkMode ? "modal__container dark" : "modal__container"}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal__header">Share with Friends</div>

            <div className="modal__icon">
              <FacebookShareButton url={url}>
                <FacebookIcon />
              </FacebookShareButton>

              <FacebookMessengerShareButton url={url}>
                <FacebookMessengerIcon />
              </FacebookMessengerShareButton>

              <LinkedinShareButton url={url}>
                <LinkedinIcon />
              </LinkedinShareButton>

              <TwitterShareButton url={url}>
                <TwitterIcon />
              </TwitterShareButton>

              <EmailShareButton url={url}>
                <EmailIcon />
              </EmailShareButton>
            </div>

            <div className="modal__links">
              <div className="modal__links__header">
                <p>Event URL</p>

                <CopyToClipboard text={url}>
                  <ContentCopyIcon />
                </CopyToClipboard>
              </div>

              <div className="modal__links__mainlink">
                {urlTruncate.slice(0, 40) + "..."}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Modal;
