import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import Share from "../share/Share";
import { HeavenyaBanner, Logo, ProfileAvatar } from "../../imports";
import axios from "axios";
import FeaturedModal from "../modal/FeaturedModal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import data from "./data";
import Lazy from "../../pages/home/Lazy";
import Linkify from "react-linkify";
import { ThemeContext } from "../../App";
import LazyHead from "../lazy load/LazyHead";
import Context from "../context/Context";

function Header() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [featured, setFeatured] = useState([]);
  const [loading, setLoading] = useState(true);
  const [autoScroll, setAutoScroll] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const featuredLength = featured.data?.length;
  // const featuredLength = data.length;
  const { theme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(false);
  const { user } = useContext(Context);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  // setting slide
  const slide = () => {
    setCurrentSlide(currentSlide === featuredLength - 1 ? 0 : currentSlide + 1);
  };

  let slideInterval;
  let intervalTime = 5000;

  const featuredEvent =
    "https://us-central1-heavenya.cloudfunctions.net/getFeaturedEvents";

  const autoSlide = () => {
    slideInterval = setInterval(slide, intervalTime);
  };

  useEffect(() => {
    featuredLength > 1 && autoScroll && autoSlide();
    return () => clearInterval(slideInterval);
  }, [currentSlide, featuredLength]);

  useEffect(() => {
    const featuredEvents = async () => {
      await axios.get(featuredEvent).then((res) => {
        setFeatured(res);
        setLoading(false);
      });
    };

    featuredEvents();
  }, []);

  const openModal = () => {
    setShowModal((prev) => !prev);
    setFeatured(featured);
  };

  return (
    <>
      {loading ? (
        <LazyHead />
      ) : (
        // <div className="load__spinner">
        //   <CircularProgress />
        // </div>
        <div className="header">
          <div className="header__logo">
            <div className="header__logo__first"></div>

            <div className="header__logo__img">
              <img src={Logo} alt="logo" />
            </div>
            {user ? (
              <div className="header__nav user">
                {user.photoURL !== null ? (
                  <div className="header__nav__signin">{user.photoURL}</div>
                ) : (
                  <div className="header__nav__photo">
                    <img src={ProfileAvatar} alt="profile avatar" />
                  </div>
                )}
                <div className="header__nav__event">
                  <a href="/myevents">my events</a>
                </div>
              </div>
            ) : (
              <div className="header__nav">
                <div className="header__nav__event">my events</div>

                <div className="header__nav__signin">sign in</div>
              </div>
            )}
          </div>

          {featured.data?.map((event, index) => {
            return (
              <div key={event.key}>
                {index === currentSlide && (
                  <div
                    className={
                      featuredLength > 1
                        ? "header__banner slide"
                        : "header__banner"
                    }
                  >
                    <div className="header__banner__image">
                      <Link
                        to={`/featuredevent/${event.name
                          .replace(/ /g, "-")
                          .replaceAll("?", "-")}/${event.key}`}
                        style={{ textDecoration: "none" }}
                      >
                        <img
                          src={event.img}
                          alt="banner"
                          className="header__banner__main"
                        />
                      </Link>
                    </div>

                    <div className="header__banner__arrow" onClick={openModal}>
                      <Share />
                    </div>

                    <div key={event.key}>
                      <div
                        className={
                          darkMode
                            ? "header__footer__content dark"
                            : "header__footer__content"
                        }
                      >
                        <div
                          className={
                            darkMode
                              ? "header__footer__content__featured dark"
                              : "header__footer__content__featured"
                          }
                        >
                          <h3>FEATURED EVENT</h3>
                          <h4>
                            <Link
                              to={`featuredevent/${event.name
                                .replace(/ /g, "-")
                                .replaceAll("?", "-")}/${event.key}`}
                              className="header__footer__content__featured__link"
                            >
                              {event.name}
                            </Link>
                          </h4>

                          {event.timeArr.length > 1 ? (
                            <div
                              className={
                                darkMode
                                  ? "header__footer__content__featured__date dark"
                                  : "header__footer__content__featured__date"
                              }
                            >
                              <p>
                                {new Date(event.timeArr[0].date).toDateString()}{" "}
                                {new Date(event.timeArr[0].sTime)
                                  .toLocaleTimeString()
                                  .replace(/(.*)\D\d+/, "$1")}{" "}
                                -{" "}
                                {new Date(
                                  event.timeArr[event.timeArr?.length - 1].date
                                ).toDateString()}{" "}
                                {new Date(
                                  event.timeArr[event.timeArr?.length - 1].sTime
                                )
                                  .toLocaleTimeString()
                                  .replace(/(.*)\D\d+/, "$1")}{" "}
                              </p>
                            </div>
                          ) : (
                            <div className="header__footer__content__featured__date">
                              <p>
                                {new Date(event.timeArr[0].date).toDateString()}{" "}
                                {new Date(event.timeArr[0].sTime)
                                  .toLocaleTimeString()
                                  .replace(/(.*)\D\d+/, "$1")}{" "}
                              </p>
                            </div>
                          )}

                          {/* {event.timeArr.map((time, i) => {
                            return (
                              <div key={i}>
                                <div className="header__footer__content__featured__date">
                                  <p>{new Date(time.date).toDateString()}</p>
                                  <p>
                                    {new Date(time.sTime)
                                      .toLocaleTimeString()
                                      .replace(/(.*)\D\d+/, "$1")}{" "}
                                    GMT -{" "}
                                    {new Date(time.eTime)
                                      .toLocaleTimeString()
                                      .replace(/(.*)\D\d+/, "$1")}{" "}
                                    GMT
                                  </p>
                                </div>
                              </div>
                            );
                          })} */}
                        </div>

                        <div className="header__footer__content__location">
                          <h5>LOCATION ADDRESS</h5>

                          <Link
                            to={`featuredevent/${event.name
                              .replace(/ /g, "-")
                              .replaceAll("?", "-")}/${event.key}`}
                            style={{ textDecoration: "none" }}
                          >
                            <p>{event.location.address}</p>
                          </Link>
                        </div>

                        <div
                          className={
                            darkMode
                              ? "header__footer__content__details dark"
                              : "header__footer__content__details"
                          }
                        >
                          <p>DETAILS</p>

                          <Link
                            to={`featuredevent/${event.name
                              .replace(/ /g, "-")
                              .replaceAll("?", "-")}/${event.key}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <a
                                  target="blank"
                                  href={decoratedHref}
                                  key={key}
                                  className={
                                    darkMode
                                      ? "header__footer__content__details__link"
                                      : "header__footer__content__details"
                                  }
                                >
                                  {decoratedText}
                                </a>
                              )}
                            >
                              <p className="header__footer__content__details__p">
                                {event.description.toString().slice(0, 120)}
                              </p>
                            </Linkify>
                          </Link>
                        </div>

                        <div className="header__footer__maincontainer__img">
                          {event.goingImage?.map((img, i) => {
                            return (
                              <div
                                className="header__footer__maincontainer__img__avatars"
                                key={i}
                              >
                                <Link
                                  to={`featuredevent/${event.name
                                    .replace(/ /g, "-")
                                    .replaceAll("?", "-")}/${event.key}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <img src={img} alt="avatar" />{" "}
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* modal specifically for featured events */}
                <FeaturedModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  name={event.name}
                  userId={event.key}
                  userIdTrunc={event.key.toString()}
                />
              </div>
            );
          })}
          {/* ) : (
            <div className="header__banner__off">
              <img src={HeavenyaBanner} alt="" />
            </div>
          )} */}
        </div>
      )}
    </>
  );
}

export default Header;
