import React from "react";
import "./Share.css";
import { Arrow, ShareLightIcon } from "../../imports";
import { useState } from "react";
import { ThemeContext } from "../../App";
import { useContext } from "react";
import { useEffect } from "react";

function Share() {
  const { theme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  return (
    <>
      <div className="share dark">
        {darkMode ? (
          <img src={Arrow} alt="share" />
        ) : (
          <div className="share light">
            <img src={ShareLightIcon} alt="share" id="share" />
          </div>
        )}{" "}
      </div>
    </>
  );
}

export default Share;
