import React, { useContext, useEffect, useState } from "react";
import {
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
} from "../../firebase";
import "./RegisterModal.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ThemeContext } from "../../App";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function RegisterModal({ showModal, setShowModal }) {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showLostPasswordPage, setShowLostPasswordPage] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const [darkMode, setDarkMode] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  // sign up switch

  const signupToggle = () => {
    setShowSignup(true);
    setShowLogin(false);
    setShowLostPasswordPage(false);
  };

  // sign in switch

  const loginToggle = () => {
    setShowLogin(true);
    setShowSignup(false);
    setShowLostPasswordPage(false);
  };

  // forgot password switch

  const lostPasswordToggle = () => {
    setShowLogin(false);
    setShowSignup(false);
    setShowLostPasswordPage(true);
  };

  // goBack switch

  const goBack = () => {
    setShowLogin(true);
    setShowSignup(false);
    setShowLostPasswordPage(false);
  };

  // sign up auth

  const handleSignUp = async (e) => {
    e.preventDefault();
    await registerWithEmailAndPassword(username, email, password);
  };

  // reset password

  const resetPassword = async (e) => {
    e.preventDefault();
    await sendPasswordReset(email);
  };

  // sign in auth

  const handleSignIn = async (e) => {
    e.preventDefault();
    await logInWithEmailAndPassword(email, password);
  };

  // Password toggle handler

  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      {showModal ? (
        <div className="registermodal" onClick={() => setShowModal(false)}>
          <div
            className={
              darkMode
                ? "registermodal__container dark"
                : "registermodal__container"
            }
            onClick={(e) => e.stopPropagation()}
          >
            <div className="registermodal__container__header">Heavenya</div>

            <div
              className={
                showLostPasswordPage
                  ? "registermodal__container__action none"
                  : "registermodal__container__action"
              }
            >
              <div
                onClick={loginToggle}
                className={
                  showLogin
                    ? "registermodal__container__action__login"
                    : showLostPasswordPage
                    ? "registermodal__container__action__login none"
                    : "registermodal__container__action__login inactive"
                }
              >
                login
              </div>
              <div
                onClick={signupToggle}
                className={
                  showSignup
                    ? "registermodal__container__action__signup"
                    : showLostPasswordPage
                    ? "registermodal__container__action__signup none"
                    : "registermodal__container__action__signup inactive"
                }
              >
                sign up
              </div>
            </div>

            <div className="registermodal__container__forms">
              <div
                className={
                  showLogin
                    ? "registermodal__container__forms__login"
                    : "registermodal__container__forms__login inactive"
                }
              >
                <form>
                  <p>Email</p>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <p>password</p>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="registermodal__container__forms__login__password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <p
                    className="registermodal__container__forms__login__showpassword"
                    onClick={togglePassword}
                  >
                    {passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </p>

                  <div onClick={lostPasswordToggle}>forgot password?</div>

                  <button onClick={handleSignIn} type="submit">
                    Login
                  </button>
                </form>
              </div>

              <div
                className={
                  showSignup
                    ? "registermodal__container__forms__signup"
                    : "registermodal__container__forms__signup inactive"
                }
              >
                <form>
                  <p>Email</p>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <p>Username</p>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />

                  <p>password</p>
                  <input
                    className="registermodal__container__forms__signup__password"
                    type={passwordShown ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <p
                    className="registermodal__container__forms__signup__showpassword"
                    onClick={togglePassword}
                  >
                    {passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </p>

                  <button type="submit" onClick={handleSignUp}>
                    Sign Up
                  </button>
                </form>
              </div>
            </div>

            <div
              className={
                showLostPasswordPage
                  ? "registermodal__container__lostpassword"
                  : "registermodal__container__lostpassword inactive"
              }
            >
              <p>enter your email</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <button onClick={resetPassword} type="submit">
                reset password
              </button>
              <div onClick={goBack} type="submit">
                <ArrowBackIcon />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default RegisterModal;
