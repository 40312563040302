import React, { useContext, useEffect, useState } from "react";
import "./About.css";
import { ThemeContext } from "../../App";
import Toggle from "../../components/toggle/Toggle";
import Text from "./Test";

function About() {
  const [darkMode, setDarkMode] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);
  return (
    <>
      <Toggle />
      <div className={darkMode ? "about dark" : "about"}>
        <div className="about__header">
          <div className="about__header__text">about heavenya</div>
        </div>

        <div className="about__text__container">
          <p>
            Heavenya is a tech nonprofit pioneering excellent technology for
            every believer to become united and inspired. We aim to preserve the
            Christian community in the realm of technology for upcoming
            generations.
          </p>
        </div>

        <div className="about__heading__text">product</div>

        <div className="about__text__container second">
          <p>
            Vision: Heavenya is a Centralized Christian fellowship app bringing
            believers together through events. Events include small groups, life
            groups, community groups, bible studies, concerts, conferences,
            seminars, retreats, celebrations and anything else faith based.
          </p>

          <p>
            Mission: The purpose of this product is to end loneliness by giving
            believers easy access to fellowship experiences to enjoy after work
            and on the weekend.
          </p>

          <p>
            Impact Metrics: The current impact metrics that we use to measure
            success are percentage of people who attend at least one event per
            week, average number of attendees per event, total events promoted,
            and total groups listed.
          </p>

          <p>
            Why: We are creating Heavenya to be one place for believers in
            Christ to join in fellowship with healthy relationships, community,
            experiences, support, and connection. Without Heavenya isolation
            among believers will continue growing at rapid speeds. Our
            destination is to connect the Christian community together in the
            realm of technology. The success of Heavenya will lead to fellowship
            among believers with faithful lives pursuing the gospel together.
            With all the new advancements in society today studies have still
            shown that people are lonely and we want to provide believers with
            ways to meet face to face, share life together, and create lasting
            relationships. Getting together is valuable and we hope to make it
            more accessible!
          </p>
        </div>

        <div className="about__heading__text">what we do</div>

        <div className="about__text__container">
          <p>
            We create software for individual believers of all denominations of
            Christianity.
          </p>
        </div>

        <div className="about__heading__text">fellowship fund</div>

        <div className="about__text__container">
          <p>
            In addition to our primary focus of software we also sponsor the
            promotion of fellowship experiences for believers. These experiences
            range from mission trips, community outreach, serving to help those
            less fortunate, and much more!
          </p>
        </div>

        <div className="about__heading__text">How You Can Help</div>

        <div className="about__text__container">
          <p>
            We are a team of united digital missionaries at Heavenya a faith
            based 501c(3) nonprofit organization. To continue our mission we
            would love your support you can donate on our website.
          </p>
        </div>

        <div className="about__heading__text">Why We Do It?</div>

        <div className="about__text__container">
          <p>
            We do it for God and you! We have never known another message that
            has the ability to transform an individual’s life, heart, identity,
            and eternal destiny.
          </p>
        </div>

        <div className="about__heading__text">Values</div>

        <div className="about__text__container">
          <ul>
            <li>Love for God and Others</li>

            <li>Frequent Scientific Experimentation</li>

            <li>Beneficiary Obession</li>

            <li>Rapid Adaptability</li>

            <li>Professional Excellence</li>

            <li>Transparent, Clear Communication</li>

            <li>Vibrant Energy</li>

            <li>Generous</li>

            <li>Let go quickly</li>
          </ul>
        </div>

        <div className="about__heading__text">Cofounder Story</div>

        <div className="about__text__container">
          <p>
            When I was 6 years old my half brother passed away. It started the
            quest on figuring out what happens to life after death? What
            happened to my brother? When I was growing up I was a cultural
            Christian. The Bible stories seem nice just like the Easter Bunny
            and Santa Claus except with more rules. I'm naturally a contrarian
            and I didn't think being a cultural Christian was the best way to
            live. So I started searching into other spiritual paths, archeology,
            and testimonies. A particular story that inspired was about a man
            who lived a life in crime but during his time in prison found faith
            in God. He came out and became a family centric man and started a
            successful roofing business. This along with many stories led me to
            think there was something special about Jesus. During highschool at
            the age of 16 I took personal prayer time more seriously with God
            and began to notice changes in attitude, perspective, and desires.
            During that time I promoted a lot of nightlife activities and
            parties which was fun and created great memories. However, I
            observed the effect that these parties and activities had on people.
            It began moving in a direction where those recreational habits
            became addictions. I saw a trajectory that gripped my heart. A new
            social platform was becoming popular at this time and I created an
            account called Team Jesus with the username christianlyfe with the
            intention to promote faith events but with the current schedule I
            just didn't get around to it. As my personal faith journey grew I
            began to fantasize about being a missionary. Usually known for going
            overseas but I also had this interest in technology and realized
            this is not going to cut it. The 2 came together and Heavenya was
            born. I became a digital missionary at Heavenya since people have a
            life that comes to pass and if God is real I want to make the most
            of my time here in this life with eternal perspective. So here at
            Heavenya we are pioneering excellent technology for every believer
            to become united and inspired.
          </p>
        </div>
      </div>

      {/* <Text /> */}
    </>
  );
}

export default About;
