import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../App";
import Channel from "../modal/Channel";
import MoreModal from "../modal/MoreModal";
import Toggle from "../toggle/Toggle";
import "./Footer.css";

function Footer() {
  const [showChannelModal, setShowChannelModal] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);

  // channel modal

  const openChannelModal = () => {
    setShowChannelModal((prev) => !prev);
  };

  // more modal

  const openMoreModal = () => {
    setShowMoreModal((prev) => !prev);
  };

  const [darkMode, setDarkMode] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  return (
    <>
      {/* {featured && ( */}
      <Toggle />

      <>
        <div className={darkMode ? "footer dark" : "footer"}>
          <div className="footer__logo">
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              Heavenya
            </Link>
          </div>

          <div className="footer__links">
            <ul>
              <Link to={"/about"}>
                <li>about</li>
              </Link>

              <a
                href="mailto:support@heavenya.com"
                target="_blank"
                rel="noreferrer"
              >
                contact
              </a>

              <div onClick={openChannelModal}>
                <li>channels</li>
              </div>

              <div onClick={openMoreModal}>
                <li>more</li>
              </div>
            </ul>
          </div>
        </div>

        <Channel
          showChannelModal={showChannelModal}
          setShowChannelModal={setShowChannelModal}
        />

        <MoreModal
          showMoreModal={showMoreModal}
          setShowMoreModal={setShowMoreModal}
        />
      </>
      {/* )} */}
    </>
  );
}

export default Footer;
