import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import Toggle from "../../components/toggle/Toggle";
import "./Legal.css";

function Legal() {
  const [darkMode, setDarkMode] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);
  return (
    <>
      <Toggle />
      <div className={darkMode ? "legal dark" : "legal"}>
        <div className="legal__header">
          <div className="legal__header__text">terms of use</div>
        </div>

        <div className="legal__heading__text">key points</div>

        <div className="legal__text__container">
          <ul>
            <li>We trust you</li>

            <li>Everyone is welcome</li>

            <li>We advocate a faith based event ecosystem</li>

            <li>
              These policies are simply to preserve the integrity of our
              services
            </li>
          </ul>
        </div>

        <div className="legal__heading__text">definitions</div>

        <div className="legal__text__container">
          <ul>
            <li>Heavenya means Heaven Yeah!</li>

            <li>
              Services means any service created by Heavenya (ex. Website,
              Mobile app)
            </li>

            <li>User means an individual who use any services</li>

            <li>Suspended means unable to access your account</li>
          </ul>
        </div>

        <div className="legal__heading__text">Use Of Heavenya Services</div>

        <div className="legal__text__container plain">
          <p>
            Eligibility for use of the Heavenya Services is contingent upon
            meeting the following conditions:
          </p>
        </div>

        <div className="legal__text__container">
          <ul>
            <li>
              You use the Services according to these Terms of Use and all
              applicable laws and regulations determined by the state and
              country of residence
            </li>

            <li>
              You provide complete and accurate registration information and
              maintain accurate registration information on the Website
            </li>

            <li>
              You agree and understand that Heavenya may, at any time, and
              without prior notice, revoke and/or cancel your access if you fail
              to meet these criteria or violate any portion of these Terms of
              Use
            </li>

            <li>
              In connection with your use of our Services, you must act
              responsibly and exercise good judgment. Without limiting the
              foregoing, you will not:
            </li>

            <li>
              Violate any local, state, provincial, national, or other law or
              regulation, or any order of a court
            </li>

            <li>
              Infringe the rights of any person or entity, including without
              limitation, their intellectual property, privacy, publicity or
              contractual rights
            </li>

            <li>
              Interfere with or damage our Services, including, without
              limitation, through the use of viruses, cancel bots, Trojan
              horses, harmful code, flood pings, denial-of-service attacks,
              packet or IP spoofing, forged routing or electronic mail address
              information orsimilar methods or technology
            </li>

            <li>
              Use automated scripts to collect information or otherwise interact
              with the Services or the Website
            </li>

            <li>
              Enter into this agreement on behalf of another person or entity
              without consent or the legal capacity to make such agreements as a
              representative of an organization or entity
            </li>
          </ul>
        </div>

        <div className="legal__heading__text">Intellectual Property</div>

        <div className="legal__text__container">
          <p>
            All code, text, software, scripts, graphics, files, photos, images,
            logos, and materials contained on this Website, or within the
            Services, are the sole property of Heavenya. Unauthorized use of any
            materials contained on this Website or within the Service may
            violate copyright laws, trademark laws, the laws of privacy and
            publicity, and/or other regulations and statutes. If you believe
            that any of the materials infringe on any third party's rights,
            please contact Heavenya immediately at the address provided below.
          </p>
        </div>

        <div className="legal__heading__text">Third Party Websites</div>

        <div className="legal__text__container second">
          <p>
            Our Website may link you to other sites on the Internet or otherwise
            include references to information, documents, software, materials
            and/or services provided by other parties. These websites may
            contain information or material that some people may find
            inappropriate or offensive.
          </p>

          <p>
            These other websites and parties are not under our control, and you
            acknowledge that we are not responsible for the accuracy, copyright
            compliance, legality, decency, or any other aspect of the content of
            such sites, nor are we responsible for errors or omissions in any
            references to other parties or their products and services. The
            inclusion of such a link or reference is provided merely as a
            convenience and does not imply endorsement of, or association with,
            the Website or party by us, or any warranty of any kind, either
            express or implied. The App is independent of any platform on which
            it's located. The App is not associated, affiliated, sponsored,
            endorsed or in any way linked to any platform operator, including,
            without limitation, Apple, Google, Android (each being an
            “Operator”).
          </p>
        </div>

        <div className="legal__heading__text">
          Disclaimer of Warranty and Limitation of Liability
        </div>

        <div className="legal__text__container second">
          <p>
            The Website is provided "AS IS." app figures, its suppliers,
            officers, directors, employees, and agents exclude and disclaim all
            representations and warranties, express or implied, related to this
            Website or in connection with the Services. You exclude Heavenya
            from all liability for damages related to or arising out of the use
            of this Website.
          </p>

          <p>
            Heavenya retains the right to, at any time, modify or discontinue,
            any or all parts of any of our services without notice.
          </p>

          <p>
            Additionally, Heavenya reserves the right, in its sole discretion,
            to modify these Terms of Use at any time, effective by posting new
            terms on the Website with the date of modification. You are
            responsible for reading and understanding the terms of this
            agreement prior to registering with, or using the Service. Your use
            of the Services after anysuch modification has been published
            constitutes your acceptance of the new terms as modified in these
            Terms of Use.
          </p>

          <p>
            <span className="legal__italics">
              Violent outbreak at an event:
            </span>
            If there is a natural disaster or violent outbreak at the event we
            do not take responsibility for the outcome.
          </p>
        </div>

        <div className="legal__heading__text">Notification</div>

        <div className="legal__text__container second">
          <p>
            <span className="legal__italics">Location:</span>
            Which may come via email or as push notification through the mobile
            app which can be stopped by unsubscribing in at the bottom of the email
            or turning off the notifications in your settings
          </p>
        </div>


        <div className="legal__heading__text" id="ticketing">Ticketing</div>

        <div className="legal__text__container second">
          <p>
            <span className="legal__italics">Tickets:</span>
            Heavenya is a platform that serves as an intermediary
            between event organizers and attendees. Our platform
            allows organizers to create and manage events, whether
            they are free or paid. The availability of tickets, pricing,
            and refund policy are at the discretion of the organizer. Our
            goal is to provide a streamlined and user-friendly experience
            for ticket purchasers. At present, the payment method we accept
            is credit card,but we may incorporate other payment options in the future.
            In the event of a dispute, the organizer is responsible for resolving the issue.
            Thank you for choosing Heavenya.com as your preferred online ticketing platform.
            We strive to provide you with the best experience possible, and part of that experience
            involves maintaining a clear and transparent ticketing policy. Please take a moment to

            familiarize yourself with the following terms and conditions:
            <br />
            <br />

            <ul><b>Ticket Purchase</b>
              <li>All tickets purchased through Heavenya.com are non-transferable and non-refundable unless otherwise specified. Tickets may not be resold or exchanged for any reason, and any such attempts will result in immediate cancellation of the ticket without refund.</li>

              <br />

              <b>Ticket Pricing</b>
              <li>Heavenya.com reserves the right to adjust ticket prices at any time without prior notice. Prices are determined based on several factors, including but not limited to venue capacity, ticket demand, and availability.</li>

              <br />

              <b>Ticket Delivery</b>
              <li>Tickets will be delivered to the email address provided during the purchase process. It is the responsibility of the purchaser to ensure that the email address provided is accurate and up-to-date. Heavenya.com is not responsible for any lost or delayed tickets due to incorrect email addresses.</li>

              <br />

              <b>Event Cancellation or Postponement</b>
              <li>In the event that an event is cancelled or postponed, Heavenya will make every effort to notify ticket holders via email as soon as possible. Refunds for cancelled events will be issued automatically within 10 business days to the original payment method used for the purchase. In the case of postponed events, ticket holders will be given the option to receive a refund or retain their tickets for the rescheduled date.</li>

              <br />

              <b>Event Seating</b>
              <li>Heavenya does not guarantee specific seating arrangements for any event. Seating is assigned on a first-come, first-served basis, and Heavenya.com reserves the right to modify seating arrangements at any time without prior notice.</li>

              <br />

              <b>Code of Conduct</b>
              <li>Heavenya expects all ticket holders to conduct themselves in a manner that is respectful to other patrons, performers, and venue staff. Any disruptive or inappropriate behavior will result in immediate ejection from the event without refund.</li>

              <br />

              Thank you for taking the time to review our ticketing policy. If you have any further questions or concerns, please do not hesitate to contact us at support@heavenya.com
            </ul>
          </p>
        </div>

        <div className="legal__heading__text">Potential Issues</div>

        <div className="legal__text__container second">
          <p>
            <span className="legal__italics">Location:</span> The Mobile
            Application, the Services are intended solely for use by Users who
            access the Mobile Application. We are not regionally limited with
            our services.
          </p>

          <p>
            <span className="legal__italics">Report:</span> If your account
            posting false event information your account is subject to become
            suspended. If you see a bug in or anyone creating false information,
            spamming, or mistreating others, you can report this in the setting
            section of the application.
          </p>

          <p>
            <span className="legal__italics">Profile:</span> Information on
            profile such as personal profile should be a photo of you as well.
          </p>

          <p>
            <span className="legal__italics">Events:</span> Any event
            information that you're hosting is expected that you will be there
            in attendance and is legitimate information.
          </p>

          <p>
            <span className="legal__italics">Accurate information:</span> You
            warrant that all information provided on Registration and contained
            as part of your Account is true, complete and accurate and that you
            will promptly inform us of any changes to such information by
            updating the information in your Account.
          </p>

          <p>
            <span className="legal__italics">Scope:</span> The Services provided
            are free without advertisement.
          </p>

          <p>
            <span className="legal__italics">Prevention on use:</span> We
            reserve the right to prevent you using the Mobile Application and
            the Service (or any part of them) Application.
          </p>

          <p>
            <span className="legal__italics">Data Loss:</span> If important or
            personal information is lost on our service weare not liable but
            will remain accountable to give an effort to restore for you.
          </p>

          <p>
            <span className="legal__italics">Minor:</span> If you are under the
            age of 18 your legal guardian should determine the appropriate use
            for the Heavenya platform. Heavenya is an intermerdiary platform and
            if an event has a negative consequece we are not held responsible.
          </p>

          <p>
            <span className="legal__italics">Service Rights:</span> We do not
            warrant that your use of the Services or the Mobile Application will
            be uninterrupted and we do not warrant that any information (or
            messages) transmitted via the Services or the Mobile Application
            will be transmitted accurately, reliably, in a timely manner or at
            all. Not with standing that we will try to allow uninterrupted
            access to the Services and the Mobile Application, access to the
            Services and the Mobile Application may be suspended, restricted or
            terminated at any time. We reserve the right to change, modify,
            substitute, suspend or remove without notice any information or
            Services on the Mobile Application from time to time. Your access to
            the Mobile Application and/or the Services may also be occasionally
            restricted to allow for repairs, maintenance or the introduction of
            new facilities or services. We will attempt to restore such access
            as soon as we reasonably can. For the avoidance of doubt, we reserve
            the right to withdraw any information or Services from the Mobile
            Application at any time. We reserve the right to block access to
            and/or to edit or remove any material which in our reasonable
            opinion may give rise to a breach of these Terms of use.
          </p>
        </div>

        <div className="legal__heading__text">Accountable Obligations</div>

        <div className="legal__text__container second">
          <p>
            <span className="legal__italics">
              Content Uploaded To Services:
            </span>
            It's your responsibility to ensure that post appropriate content
            that is not harmful, false, hurtful, explicit, threatening, grossly
            offensive, of an indecent, obscene or menacing character,
            blasphemous or defamatory of any person, in contempt of court or in
            breach of confidence, copyright, rights of personality, publicity or
            privacy or any other third party rights or false.
          </p>

          <p>
            <span className="legal__italics">Hacking:</span> To send or receive
            any material which is technically harmful (including computer
            viruses, logic bombs, Trojan horses, worms, harmful components,
            corrupted data or other malicious software or harmful data)
          </p>

          <p>
            You agree to indemnify and keep us indemnified against any claim,
            action, suit or proceeding brought or threatened to be brought
            against us which is caused by or arising out of (a) your use of the
            Services, (b) any other party’s use of the Services using your user
            ID verification.
          </p>
        </div>

        <div className="legal__heading__text">Cults</div>

        <div className="legal__text__container second">
          <p>
            Cult is the first part of the word culture which can be a positive
            in many ways. However sometimes as we have seen throughout history
            cults can become a very negative experience. Here at Heavenya we
            hope to do our part and help stop this. If you have joined in
            fellowship with a group that seems to be a bit off it may just not
            be the right group. If you feel that the group has harmful
            intentions, unusual practices and leadership such as people are
            shamed for questioning authority, the authority is vested in one
            person, there is enforced attendance, or other tell tale signs
            trying taking this quiz https://cult-escape.com/cult-test/ Also we
            recommend checking out a different group and reporting this inside
            the Heavenya app.
          </p>
        </div>

        <div className="legal__heading__text">Suspension And Termination</div>

        <div className="legal__text__container">
          <ul>
            <li>
              If you use (or anyone other than you, with your permission uses)
              the Mobile Application, any Services in contravention of these
              Terms and Conditions of Use, we may suspend your use of the
              Services and/or Mobile Application.
            </li>

            <li>
              If we suspend the Services or Mobile Application, we may refuse to
              restore the Services or Mobile Application for your use until
              wereceive an assurance from you, in a form we deem acceptable,
              that there will be no further breach of the provisions of these
              Terms and Conditions of Use.
            </li>

            <li>
              If we suspect, on reasonable grounds, that you have, might or will
              commit a breach of these Terms Of Use and if we suspect, on
              reasonable grounds, that you may have committed or be committing
              any fraud against us or any person.
            </li>
          </ul>
        </div>

        <div className="legal__heading__text">Indemnification</div>

        <div className="legal__text__container">
          <p>
            You agree to indemnify Heavenya and each of our affiliates,
            successors and assigns, and their respective officers, directors,
            employees, agents, representatives, licensors, advertisers,
            suppliers, and operational service providers harmless from and
            against any and all losses, expenses, damages, costs and expenses
            (including attorneys’ fees), resulting from your use of the App
            and/or any violation of the terms of this Agreement. We reserve the
            right to assume the exclusive defense and control of any demand,
            claim or action arising hereunder or in connection with the App and
            all negotiations for settlement or compromise. You agree to fully
            cooperate with us in the defense of any such demand, claim, action,
            settlement or compromise negotiations, as requested by us.
          </p>
        </div>

        <div className="legal__heading__text">Governing Law</div>

        <div className="legal__text__container second">
          <p>
            These Terms of Use and any dispute or claim arising out of, or
            related to them, shall be governed by and construed in accordance
            with the internal laws of the United States without giving effect to
            any choice, conflict of law provision or rule.
          </p>
          <p>
            Any legal suit, action or proceeding arising out of, or related to,
            these Terms of Use or the Website shall be instituted exclusive.
          </p>
        </div>

        <div className="legal__header">
          <div className="legal__header__text">Privacy Policy</div>
        </div>

        <div className="legal__text__container second">
          <p>
            This Privacy Policy will help you understand how Heavenya.com and
            serivces (“Heavenya”, “us”, “our” or “we”) processes any and all
            data and information collected in relation to your use of our
            website. We are committed to protecting and respecting your privacy.
            By using this website, you consent to this Privacy Policy.
            Information Collected We collect information from visitors to
            Heavenya through data capture forms and contact forms. We collect
            your details when you email us with an enquiry. We process the
            personal information you have given for the purposes of providing:
            The information you have requested appropriate and useful
            communications assistance to help improve our services and your
            experiences. In addition to the above, we may collect information
            automatically about your visit to our website through the use of
            Cookies or Log Files, as described below.
          </p>
        </div>

        <div className="legal__heading__text">Personal Information</div>

        <div className="legal__text__container plain">
          <p>
            As part of the normal operation of our Services, we collect, use and
            in some cases, disclose information about you to third parties.
          </p>

          <p>
            Accordingly, we have developed this Privacy Policy in order for you
            to understand how we collect, use and communicate, and disclose as
            well as make use of your personal information when you use the
            Services on the Mobile Application:
          </p>
        </div>

        <div className="legal__text__container ordered">
          <ul>
            <li>
              Before or at the time of collecting personal information, we will
              identify the purposes for which information is being collected.
            </li>

            <li>
              We will collect and use personal information solely with the
              objective of fulfilling those purposes specified by us and for
              other compatible purposes, unless we obtain the consent of the
              individual concerned or as required by law.
            </li>

            <li>
              We will only retain personal information as long as necessary for
              the fulfillment of those purposes.
            </li>

            <li>
              We will collect personal information by lawful and fair, where
              appropriate, with the knowledge or consent of the individual
              concerned.
            </li>

            <li>
              Personal information should be relevant to the purposes for which
              it's to be used, and to the extent necessary for those purposes,
              should be accurate, complete, and up-to-date.
            </li>

            <li>
              We will protect personal information by reasonable security
              safeguards against loss or theft, as well as unauthorized access,
              disclosure, copying, use or modification.
            </li>
          </ul>
        </div>

        <div className="legal__text__container plain">
          <p>
            We are committed to conducting our organization in accordance with
            these principles in order to ensure that the confidentiality of
            personal information is protected and maintained.
          </p>
        </div>

        <div className="legal__heading__text">Intellectual Property</div>

        <div className="legal__text__container">
          <p>
            Cookies and Web Beacons. A cookie is a small data file which may
            include an anonymous unique identifier that is sent to your browser
            from a website’s computers and may either be used only during your
            session (a “session” cookie) or may be stored on your computer’s
            hard drive (a “persistent” cookie). Cookies can contain data about
            user movement during the visit to the website. If your browser
            software is set to allow cookies, a website can send its own cookie
            to you. Cookies are a mechanism for maintaining continuity during a
            user’s visit to a website. They allow data to be maintained for
            users’ benefit as they navigate a site. This is referred to as a
            “session” or “management” cookie. These cookies go away when you
            terminate your visit to the website as they are maintained only in
            your browser’s active memory during your session. Cookies may also
            be stored on your computer so that you can be recognized by a
            website on subsequent visits. They are read by the website that sent
            them whenever you re-visit the website. They are often used on
            websites that require you to login so that you can avoid having to
            enter all of your login information every visit. They may store
            information on your unique identifier and the areas of the website
            you have visited before. These cookies are stored on your computer’s
            hard drive after you have left your website visit and consequently
            are often referred to as “persistent” cookies. You can configure
            your browser to accept all cookies, reject all cookies, or notify
            you when a cookie is sent. To learn more about how to manage cookies
            in your web browser, please visit
            http://www.allaboutcookies.org/manage-cookies/. Log Files Heavenya
            follows a standard procedure of using log files. Log file
            information is automatically reported by your browser each time you
            make a request to visit a webpage. The information collected by log
            files include internet protocol (IP) addresses, browser type,
            Internet Service Provide (ISP), date and time stamp, referring/exit
            pages and the number of clicks. These are not linked to any
            information that is personally identifiable. If used, the purpose of
            the information is for analyzing trends, administering the site,
            tracking users’ movement on the website and gathering demographic
            information. Children’s Information in compliance with the
            Children’s Online Privacy Protection Rule (“COPPA”), Heavenya does
            not collect any Personal Identifiable Information from children
            under the age of 13. If you think that your child provided this kind
            of information on our website, we encourage you to determine the
            apprioate use of the Heavenya platform of your discretion. You can
            learn more about COPPA at http://www.coppa.org/coppa.htm. Links to
            Other Websites. Heavenya is not responsible for the practices
            employed by any external websites or services linked to or from
            heavenya.com, including the information or content contained within
            them. Our Privacy Policy does not apply to those third-party
            websites or services. Your browsing and interaction on any
            third-party website or service, including those that have a link on
            our website, are subject to that third party’s own rules and
            policies. In addition, you agree that Heavenya is not responsible
            and does not have control over any third-parties that you visit.
            Online Privacy Policy Only. This privacy policy applies only to our
            online activities and is valid for visitors to heavenya.com with
            regards to the information that is shared and/or collected. This
            policy is not applicable to any information collected offline or via
            channels other than this website. General Disclaimer While every
            effort is made to keep information provided over the internet
            accurate and up-to-date, Heavenya does not certify the authenticity
            or accuracy of such information. No warranties, express or implied,
            are provided for the content , records and/or mapping data herein,
            or for their use or interpretation by the User. Heavenya and its’
            agents assume no legal responsibilities for the information or
            accuracy contained in this data, including any action taken from
            reliance on any information contained herein and shall haveno
            liability for any damages, losses, costs, or expenses, including,
            but not limited to attorney’s fees, arising from the use or misuse
            of the information provided herein. The User’s use thereof shall
            constitute an agreement by the User to release Heavenya and its’
            agents from such liability.
          </p>
        </div>

        <div className="legal__text__container plain footer">
          <p>Heavenya is a registered 501c(3) organization</p>

          <p>Legal Policies effective 11/5/19</p>

          <p>Last updated 4/15/23</p>
        </div>
      </div>
    </>
  );
}

export default Legal;
