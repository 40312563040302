import { Switch } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../App";
import Header from "../../components/header/Header";
import Hero from "../../components/hero/Hero";
import Toggle from "../../components/toggle/Toggle";

function Home() {
  return (
    <>
      <div className="home">
        <Toggle />
        <Header />
        <Hero />
      </div>
    </>
  );
}

export default Home;
