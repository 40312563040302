import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  OAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDHW19LdEXCipTYMZiMJaG8oGzvo1KouRs",
  authDomain: "heavenya.com",
  databaseURL: "https://heavenya.firebaseio.com",
  projectId: "heavenya",
  storageBucket: "heavenya.appspot.com",
  messagingSenderId: "9549130300",
  appId: "1:9549130300:web:8a5663f3ab3e2b9668f139",
  measurementId: "G-0SB1YT02FV",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const signInWithApple = async () => {
  try {
    const result = await signInWithPopup(auth, appleProvider).then(() => {
      alert("Login Success");
      window.location.reload();
    });
    console.log(result.user); // logged-in Apple user
  } catch (error) {
    console.log(error);
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider).then(() => {
      alert("Login Success");
      window.location.reload();
    });
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password).then(() => {
      alert("Login Success");
      window.location.reload();
    });
  } catch (err) {
    console.error(err);
    alert("email or password is incorrect");
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    ).then(() => {
      alert("Registration Success");
      window.location.reload();
    });
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  signInWithApple,
  logout,
};
