import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import "./LazyFeed.css";

export default function LazyFeed() {
  const { theme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  const FeedSkeleton = () => (
    <div className={darkMode ? "lazyfeed dark" : "lazyfeed"}>
      <div className="lazyfeed__card">
        <div className="lazyfeed__card__single">
          <div className="lazyfeed__card__img">
            <div className="lazyfeed__card__img__share"></div>
          </div>
          <div className="lazyfeed__card__title"></div>
          <div className="lazyfeed__card__time">
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={darkMode ? "card__display dark" : "card__display"}>
        <FeedSkeleton />
        <FeedSkeleton />
        <FeedSkeleton />
        <FeedSkeleton />
        <FeedSkeleton />
        <FeedSkeleton />
      </div>
    </>
  );
}
