import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import {
  Discord,
  FbChannel,
  Insta,
  LinkedinChannel,
  Medium,
  Pinterest,
  Snapchat,
  Spotify,
  Tiktok,
  TwitterChannel,
  Youtube,
  Pandora,
  Anchor,
  ApplePodcast,
  GooglePodcast,
  Overcast,
  iHeartRadio,
  Castbox,
  PocketCasts,
  RadioPublic,
  Stitcher,
  AmazonMusic,
  AmazonAudible,
  TuneIn,

} from "../../imports";
import "./Channel.css";

function Channel({ showChannelModal, setShowChannelModal }) {
  const [darkMode, setDarkMode] = useState(false);

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);
  return (
    <>
      {showChannelModal ? (
        <div className="channel" onClick={() => setShowChannelModal(false)}>
          <div
            className={
              darkMode ? "channel__container dark" : "channel__container"
            }
            onClick={(e) => e.stopPropagation()}
          >
            <div className="channel__container__header">Channels</div>

            <div className="channel__container__body">
              <a
                href="https://www.facebook.com/heavenya.events"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FbChannel} alt="facebook channel" />
              </a>

              <a
                href="https://www.instagram.com/heavenya.events/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Insta} alt="instagram channel" />
              </a>

              <a
                href="https://www.pinterest.com/heavenya_events/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Pinterest} alt="pinterest channel" />
              </a>

              <a
                href="https://twitter.com/heavenya_events"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TwitterChannel} alt="twitter channel" />
              </a>

              <a
                href="https://www.youtube.com/channel/UCnW0LL2nma3jreyHyzJnRUA?view_as=subscriber"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Youtube} alt="youtube channel" />
              </a>

              <a
                href="https://www.tiktok.com/@heavenya.events"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Tiktok} alt="tiktok channel" />
              </a>

              <a
                href="https://heavenya.medium.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Medium} alt="medium channel" />
              </a>

              <a
                href="https://www.linkedin.com/authwall?trk=bf&trkInfo=bf&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fheavenya"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedinChannel} alt="linkedin channel" />
              </a>

              <a
                href="https://discord.com/invite/Mvb5RkCHRj"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Discord} alt="discord channel" />
              </a>

              <a
                href="https://www.snapchat.com/download"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Snapchat} alt="snapchat channel" />
              </a>

              <a
                href="https://anchor.fm/heavenya"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Anchor} alt="anchor channel" />
              </a>

              <a
                href="https://open.spotify.com/show/0E3jBrXHg7hRRiX24CJMNY?si=xYb7Ef7BRfOG2OdhRjashg&nd=1"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Spotify} alt="spotify channel" />
              </a>

              <a
                href="https://www.pandora.com/podcast/heavenya/PC:1001058287"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Pandora} alt="pandora channel" />
              </a>

              <a
                href="https://podcasts.apple.com/us/podcast/heavenya/id1672800274"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ApplePodcast} alt="apple podcast channel" />
              </a>

              <a
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83NDRhODUyMC9wb2RjYXN0L3Jzcw"
                target="_blank"
                rel="noreferrer"
              >
                <img src={GooglePodcast} alt="google podcast channel" />
              </a>

              <a
                href="https://overcast.fm/itunes1672800274/heavenya"
                target="_blank"
                rel="noreferrer"

              >
                <img src={Overcast} alt="overcast channel" />
              </a>

              <a
                href="https://www.iheart.com/podcast/269-heavenya-109165614"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iHeartRadio} alt="iheartradio channel" />
              </a>

              <a
                href="https://castbox.fm/channel/Heavenya-id5321021?country=us"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Castbox} alt="castbox channel" />
              </a>

              <a
                href="https://pca.st/tb3pmj4w"
                target="_blank"
                rel="noreferrer"
              >
                <img src={PocketCasts} alt="pocket casts channel" />
              </a>

              <a
                href="https://radiopublic.com/heavenya-GKZ49l"
                target="_blank"
                rel="noreferrer"
              >
                <img src={RadioPublic} alt="radio public channel" />
              </a>

              <a
                href="https://www.stitcher.com/show/1058287"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Stitcher} alt="stitcher channel" />
              </a>

              <a
                href="https://music.amazon.com/podcasts/ac31069b-68bc-4ef7-8513-a4a80202599a/heavenya"
                target="_blank"
                rel="noreferrer"
              >
                <img src={AmazonMusic} alt="amazon music channel" />
              </a>

              <a
                href="https://www.audible.com/pd/Heavenya-Podcast/B0BW6LXDHH"
                target="_blank"
                rel="noreferrer"
              >
                <img src={AmazonAudible} alt="amazon audible channel" />
              </a>

              <a
                href="https://tunein.com/radio/Heavenya-p3113400"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TuneIn} alt="Tune In channel" />
              </a>


            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Channel;
