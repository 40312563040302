import React, { useState, useEffect, useContext } from "react";
import "./Search.css";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import "@culturehq/add-to-calendar/dist/styles.css";
import Button from "../../components/button/Button";
import Share from "../../components/share/Share";
import Modal from "../../components/modal/Modal";
import LazyFeed from "../../components/lazy load/LazyFeed";
import useGeoLocation from "../../components/geolocation/UseGeoLocation";
import { useParams } from "react-router";
import { ThemeContext } from "../../App";
import Toggle from "../../components/toggle/Toggle";

function Search() {
  const { key } = useParams();
  const [visible, setVisible] = useState(12);
  const [searchVisible, setSearchVisible] = useState(48);

  const [pagination] = useState(50);
  const [page, setPage] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [event, setEvent] = useState(null); // creating a state for modal to get data for individual card

  const [query, setQuery] = useState("");
  const [featured, setFeatured] = useState([]);
  const [search, setSearch] = useState([]);

  const [searchLoading, setsearchLoading] = useState(true);
  const [searchTimeout, setSearchTimeout] = useState(false);

  const { theme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);

  console.log(theme);
  // getting ip address

  const searchQuery = `https://us-central1-heavenya.cloudfunctions.net/searchEvents?search=${key}&page=${page}`;

  const location = useGeoLocation();
  const locationLat = JSON.stringify(location.coordinates.lat);

  const locationLong = JSON.stringify(location.coordinates.lng);

  //  displaying event only when location is set
  useEffect(() => {
    if (location.loaded === true && location.coordinates.lat !== "1") {
      const featuredData = `https://us-central1-heavenya.cloudfunctions.net/getLocalizedEvent?lat=${locationLat}&lng=${locationLong}&page=${page}&pagination=${pagination}`;
      try {
        axios.get(featuredData).then((res) => {
          setFeatured(res.data?.data);
          setsearchLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    } else if (location.loaded === true && location.coordinates.lat === "1") {
      const featuredData = `https://us-central1-heavenya.cloudfunctions.net/getLocalizedEvent`;
      try {
        axios.get(featuredData).then((res) => {
          setFeatured(res.data?.data);
          setsearchLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [location]);

  // search handling

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const searchFunction = async () => {
        try {
          await axios
            .get(searchQuery)
            .then((res) => {
              setSearch(res.data?.data);
            })
            .then(() => setSearchTimeout(true));
        } catch (error) {
          // console.log(error);
        }
      };

      key.length > 0 && searchFunction();
    }, 700);

    return () => {
      clearTimeout(delayDebounceFn);
      setSearchTimeout(false);
    };
  }, [page, key]);

  useEffect(() => {
    axios.get(searchQuery).then((e) => {});
  });

  // load more btn for local events

  const loadMoreLocal = () => {
    setVisible((prevValue) => prevValue + featured.length);
  };

  // load more btn for search queries
  const loadMoreSearch = () => {
    setSearchVisible(48);
    setPage((prevValue) => prevValue + 1);
    window.scrollTo(600, 600);
  };

  const delSearchInput = () => {
    setQuery("");
  };

  const openModal = (e) => {
    setShowModal((prev) => !prev);
    setEvent(e);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ your logic here
        window.location.replace(`/search/${query}`);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [query]);

  return (
    <>
      <Toggle />
      {!searchLoading ? (
        <>
          <div className={darkMode ? "search__header dark" : "search__header"}>
            search result for '{key}'
          </div>

          <div
            className={
              darkMode ? "search__container dark" : "search__container"
            }
          >
            <div className="search">
              {/* heading or title of the search section */}

              <div className="search__heading">
                <div
                  className={
                    darkMode
                      ? "search__heading__title dark"
                      : "search__heading__title"
                  }
                >
                  Create Fellowship Opportunities In Your Local Community
                </div>

                <div
                  className={
                    darkMode
                      ? "search__heading__search dark"
                      : "search__heading__search"
                  }
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="search events"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />

                  <div
                    className="search__heading__title__close__icon"
                    onClick={delSearchInput}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>

              <div className="search__cards__container">
                {/* mapping through the list of items imported from searchcards component and implementing search filter and view more functionality */}

                {key === "" ? (
                  <>
                    {featured.slice(0, visible).map((card) => {
                      /* cards display */

                      return (
                        <div className="search__cards" key={card.key}>
                          <div className="search__cards__img">
                            <a
                              href={`/eventdetails/${card.name
                                .replace(/ /g, "-")
                                .replaceAll("?", "-")}/${card.key}`}
                              style={{ textDecoration: "none" }}
                            >
                              <img
                                src={card.img}
                                alt="card"
                                className="search__cards__img__main"
                              />
                            </a>

                            <div
                              className="search__cards__arrow"
                              onClick={() => openModal(card)}
                            >
                              <Share />
                            </div>
                          </div>
                          <div
                            className={
                              darkMode
                                ? "search__cards__contents dark"
                                : "search__cards__contents"
                            }
                          >
                            <a
                              href={`/eventdetails/${card.name
                                .replace(/ /g, "-")
                                .replaceAll("?", "-")}/${card.key}`}
                              style={{ textDecoration: "none" }}
                            >
                              <h4>{card.name}</h4>
                            </a>
                            <div className="search__cards__contents__date">
                              {/* formatting date and time and displaying it */}

                              <p>
                                {new Date(
                                  card.timeArr[0]?.sTime
                                ).toDateString()}
                              </p>
                              <p>
                                {new Date(card.timeArr[0]?.sTime)
                                  .toLocaleTimeString([], { hour12: true })
                                  .replace(/(.*)\D\d+/, "$1")}
                                {""} - {""}
                                {new Date(
                                  card.timeArr[card.timeArr?.length - 1]?.eTime
                                )
                                  .toLocaleTimeString([], { hour12: true })
                                  .replace(/(.*)\D\d+/, "$1")}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {visible < featured.length && (
                      <div
                        className="search__view__more"
                        onClick={loadMoreLocal}
                      >
                        <Button text="load more" />
                      </div>
                    )}
                  </>
                ) : (
                  // search filtering and display
                  <>
                    {searchTimeout ? (
                      <>
                        {search
                          .filter((post) => {
                            if (key === "") {
                              return post;
                            } else if (
                              post.location?.address ||
                              "".toLowerCase().includes(query.toLowerCase())
                            ) {
                              return post;
                            } else if (
                              post.name
                                .toLowerCase()
                                .includes(key.toLowerCase())
                            ) {
                              return post;
                            }

                            return false;
                          })
                          .slice(0, searchVisible)
                          .map((card) => {
                            /* cards display */

                            return (
                              <div className="search__cards" key={card.key}>
                                <div className="search__cards__img">
                                  <a
                                    href={`/eventdetails/${card.name
                                      .replace(/ /g, "-")
                                      .replaceAll("?", "-")}/${card.key}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <img
                                      src={card.img}
                                      alt="card"
                                      className="search__cards__img__main"
                                    />
                                  </a>

                                  <div
                                    className="search__cards__arrow"
                                    onClick={() => openModal(card)}
                                  >
                                    <Share />
                                  </div>
                                </div>
                                <div
                                  className={
                                    darkMode
                                      ? "search__cards__contents dark"
                                      : "search__cards__contents"
                                  }
                                >
                                  <a
                                    href={`/eventdetails/${card.name
                                      .replace(/ /g, "-")
                                      .replaceAll("?", "-")}/${card.key}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <h4>{card.name}</h4>
                                  </a>

                                  <div className="search__cards__contents__date">
                                    {/* formatting date and time and displaying it */}

                                    <p>
                                      {new Date(
                                        card.timeArr[0]?.sTime
                                      ).toDateString()}
                                    </p>
                                    <p>
                                      {new Date(card.timeArr[0]?.sTime)
                                        .toLocaleTimeString([], {
                                          hour12: true,
                                        })
                                        .replace(/(.*)\D\d+/, "$1")}
                                      {""} - {""}
                                      {new Date(
                                        card.timeArr[
                                          card.timeArr?.length - 1
                                        ]?.eTime
                                      )
                                        .toLocaleTimeString([], {
                                          hour12: true,
                                        })
                                        .replace(/(.*)\D\d+/, "$1")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {searchVisible < search.length && (
                          <div
                            className="search__view__more"
                            onClick={loadMoreSearch}
                          >
                            <Button text="load more" />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <LazyFeed />

                        {/* <div className="load__spinner">
                          <CircularProgress />
                        </div> */}
                      </>
                    )}

                    {searchTimeout === true && search.length < 1 && (
                      <div className={darkMode ? "noresult dark" : "noresult"}>
                        Result not found.
                      </div>
                    )}
                  </>
                )}

                <Modal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  userId={event && event.key}
                  name={event && event.name}
                  eventImage={event && event.image}
                  userIdTrunc={event && event.key.toString()}
                  // eventNameTrunc={event && event.name.toString().slice(0, 8)} // truncating event name
                />
              </div>

              {/* importing a button component and passing props */}
            </div>
          </div>
        </>
      ) : (
        <LazyFeed />
        // <div className="load__spinner">
        //   <CircularProgress />
        // </div>
      )}
    </>
  );
}

export default Search;
