import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import "./Toggle.css";

function Toggle() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [day, setDay] = useState(false);

  useEffect(() => {
    if (
      new Date().getHours().toLocaleString() >= 7 &&
      new Date().getHours().toLocaleString() <= 18
    ) {
      setDay(true);
      setTheme("light");
      localStorage.setItem("mode", JSON.stringify(theme));
    } else {
      setDay(false);
      setTheme("dark");
      localStorage.setItem("mode", JSON.stringify(theme));
    }
  }, [day]);
}

export default Toggle;
