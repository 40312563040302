import "./App.css";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import SinglePost from "./pages/post/SinglePost";
import { Routes, Route } from "react-router-dom";
import Test from "./Test";
import Test2 from "./Test2";
import Context from "./components/context/Context";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import FeaturedEvent from "./pages/events/FeaturedEvent";
import About from "./pages/about/About";
import Legal from "./pages/legal/Legal";
import ScrollToTop from "./components/scrollTop/ScrollTop";
import { createContext, useState } from "react";
import LegalFooter from "./pages/legal/LegalFooter";
import Error404 from "./pages/error/404";
import Search from "./pages/search/Search";
// import UserEvents from "./pages/post events/UserEvents";
// import AddEvents from "./pages/post events/AddEvents";
// import FF from "./pages/post events/Test";

export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("mode")));

  const [data] = useAuthState(auth);
  const user = data;

  return (
    <Context.Provider value={{ user }}>
      <>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <div className="App">
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/featuredevent/:eventName/:id"
                  element={<FeaturedEvent />}
                />

                <Route path="/test" element={<Test />} />
                <Route path="/dashboard" element={<Test2 />} />
                <Route path="/about" element={<About />} />
                <Route path="/legal" element={<Legal />} />

                {/* <Route path="/myevents" element={<UserEvents />} />
                <Route path="/addevent" element={<AddEvents />} /> */}

                <Route path="/legal/hideFooter" element={<LegalFooter />} />

                <Route
                  path="/eventdetails/:eventName/:key"
                  element={<SinglePost />}
                />

                <Route
                  path="/eventdetails/:eventName/:eventName/:key"
                  element={<SinglePost />}
                />

                <Route path="/search/:key" element={<Search />} />

                {/* <Route path="/tests" element={<FF />} /> */}

                {/* error 404 */}

                <Route path="*" element={<Error404 />} />
              </Routes>
            </ScrollToTop>

            <Footer />
          </div>
        </ThemeContext.Provider>
      </>
    </Context.Provider>
  );
}

export default App;
