import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../App";
import "./LazyLoad.css";

function LazyLoad() {
  const { theme } = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    theme === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [theme]);
  return (
    <>
      <div className={darkMode ? "lazyload dark" : "lazyload"}>
        <div className="lazyload__header"></div>
        <div className="lazyload__header__bottom">
          <div className="lazyload__header__bottom__circle"></div>
          <div className="lazyload__header__bottom__text"></div>
        </div>

        <div className="lazyload__body">
          <div className="lazyload__body__attendees">
            <div className="lazyload__body__attendees__one"></div>
            <div className="lazyload__body__attendees__two"></div>
            <div className="lazyload__body__attendees__three"></div>
          </div>

          <div className="lazyload__body__main">
            <div className="lazyload__body__main__info">
              <div className="lazyload__body__main__info__heading"></div>
              <div className="lazyload__body__main__info__content"></div>
            </div>
            <div className="lazyload__body__main__location">
              <div className="lazyload__body__main__location__heading"></div>
              <div className="lazyload__body__main__location__content"></div>
            </div>

            <div className="lazyload__body__main__detais">
              <div className="lazyload__body__main__detais__heading"></div>
              <div className="lazyload__body__main__detais__content"></div>
            </div>

            <div className="lazyload__body__main__btn"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LazyLoad;
